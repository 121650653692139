import { createRouter, createWebHashHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue'

// 注册pinia 要不 router页面不可用 - start
import App from '../App.vue'
import {
  createApp
} from 'vue'
import {
  createPinia
} from 'pinia'
const app = createApp(App)
app.use(createPinia())
// 注册pinia 要不 router页面不可用 - end

import useUserStore from '@/stores/user'
const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/home',
      name: 'home',
      component: HomeView
    },

    {
      path: '/login',
      name: 'login',
      component: () => import('../views/loginPage.vue')
    },
    
    {
      path: '/assesslist',
      name: 'assesslist',
      component: () => import('../views/AssessList.vue'),
      meta: { sidebarKey: 1 },
    }, 

    {
      path: '/staffs',
      name: 'staffs',
      component: () => import('../views/DepartmentStaffs.vue'),
      meta: { sidebarKey: 2},  
    },
    {
      path: '/detail/:id',
      name: 'detail',
      component: () => import('@/views/AssessView.vue')
    },    
    {
      path: '/assesshistory',
      name: 'assesshistory',
      component: () => import('../views/AssessHistory.vue'),
      meta: { sidebarKey: 3 },
    }, 
    {
      path: '/lastmonth',
      name: 'lastmonth',
      component: () => import('../views/LastmonthAssessList.vue'),
      meta: { sidebarKey: 1},
    },   
    {
      path: '/clocklist',
      name: 'clocklist',
      component: () => import('../views/ClockList.vue'),
      meta: { sidebarKey: 4 },
    },   
    {
      path: '/worktimelist',
      name: 'worktimelist',
      component: () => import('../views/WorkTimeList.vue'),
      meta: { sidebarKey: 4 },
    },     
    {
      path: '/standardspage',
      name: 'standardspage',
      component: () => import('../views/StandardsPage.vue'),
      meta: { sidebarKey: 5 },
    },   
    {
      path: '/applications',
      name: 'applications',
      component: () => import('../views/AskForLeaveApplications.vue'),
      meta: { sidebarKey: 6 },
    },  
    {
      path: '/editAssessment/:id/:assess_id',
      name: 'editAssessment',
      component: () => import('../views/EditAssessment.vue'),
      meta: { sidebarKey:3 },
    },  
    {
      path: '/editNightshift/:id/:assess_id',
      name: 'editNightshift',
      component: () => import('../views/EditNightShift.vue'),
      meta: { sidebarKey: 3 },
    },     

    {
      path: '/editOverNightshift/:id/:assess_id',
      name: 'editOverNightshift',
      component: () => import('../views/EditOverNightShift.vue'),
      meta: { sidebarKey:3 },
    },  

    {
      path: '/editOperatingWorkload/:id/:assess_id',
      name: 'editOperatingWorkload',
      component: () => import('../views/EditOperatingWorkload.vue'),
      meta: { sidebarKey: 3},
    }, 
    {
      path: '/editTraffics/:id/:assess_id',
      name: 'editTraffics',
      component: () => import('../views/EditTraffics.vue'),
      meta: { sidebarKey: 3},
    }, 
    
    {
      path: '/editDaoyi/:id/:assess_id',
      name: 'editDaoyi',
      component: () => import('../views/EditDaoyi.vue'),
      meta: { sidebarKey: 3},
    },     

  ]
});

// 前置路由守卫
router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    next();
  } else {
    const userStore = useUserStore();
    // 已登录
    if (userStore.loginFlag === 'true') {
      next();
    }
    // 未登录
    else {
      next({
        name: 'login',
        query: {
          redirect: to.fullPath !== '/' ? to.fullPath : undefined,
        },
      });
    }
  }
});


export default router;
