import axios from 'axios';
import { ElLoading } from 'element-plus';

const api = axios.create({
  baseURL: 'https://assessapi.lcyyjk.com',
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

let loadingInstance = null;

api.interceptors.request.use(
  (config) => {
    // 检查配置项以决定是否显示加载提示
    if (config.showLoading) {
      // 显示加载提示
      loadingInstance = ElLoading.service({ fullscreen: true, text: '加载中...' });
    }
    return config;
  },
  (error) => {
    // 请求错误处理
    if (loadingInstance) loadingInstance.close();
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    // 关闭加载提示
    if (loadingInstance) {
      loadingInstance.close();
      loadingInstance = null; // 重置loadingInstance
    }
    return response;
  },
  (error) => {
    // 关闭加载提示
    if (loadingInstance) {
      loadingInstance.close();
      loadingInstance = null; // 重置loadingInstance
    }
    // 响应错误处理
    return Promise.reject(error);
  }
);

export default api;
