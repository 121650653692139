<template>
  <el-dialog v-model:visible="visible" title="加载中" width="30%">
    <div class="loading-container">
      <el-loading />
      <p>数据正在加载，请稍候...</p>
    </div>
  </el-dialog>
</template>

<script>
import { ref } from 'vue';
import { ElDialog, ElLoading } from 'element-plus';

export default {
  components: {
    ElDialog,
    ElLoading,
  },
  setup() {
    const visible = ref(false);

    const showLoading = () => {
      visible.value = true;
    };

    const hideLoading = () => {
      visible.value = false;
    };

    // 暴露方法
    return { visible, showLoading, hideLoading };
  },
};
</script>

<style scoped>
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
